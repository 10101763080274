import { type PopupProps } from "@tamarack/ui/Popup";
import Tooltip from "@tamarack/ui/Tooltip";
import HelpIcon from "@tamarack/ui/icons/HelpIcon";
import type { PropsWithChildren } from "react";

export default function HelpInfo({
  children,
  placement,
}: PropsWithChildren & { placement?: PopupProps["placement"] }) {
  return (
    <Tooltip title={children} placement={placement}>
      <HelpIcon
        size={16}
        className="cursor-help rounded-lg border-tm-divider text-tm-secondary-lighter hover:bg-tm-divider-lightest hover:text-tm-secondary hover:opacity-100"
      />
    </Tooltip>
  );
}
